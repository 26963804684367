import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToastOptions,
} from "@chakra-ui/react"
import { UseDisclosureReturn } from "@chakra-ui/react/dist/useDisclosure"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import AppContext, { actions, State } from "../../context/kwitantieContext"
import { api } from "../../settings"

const mailModal = ({
  control,
  toast,
  setIsLoading,
}: {
  control: UseDisclosureReturn
  toast: (props: useToastOptions) => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const context = useContext<any>(AppContext)
  const { dispatch, state }: { state: State; dispatch: any } = context

  const { handleSubmit, register, errors } = useForm()

  const onSubmit = values => {
    control.onClose()
    dispatch({
      type: actions.sendMail,
      payload: values,
    })

    setIsLoading(true)
    
    postData(api + "/api/SendMail", {
      ...values,
      kwitantie: state,
    })
      .then(data => {
        setIsLoading(false)
        toast({
          title: "Kwitantie verstuurd",
          description: `Naar ${values.emailTo}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(a => {
        setIsLoading(false)
        toast({
          title: "Geen verbinding mogelijk met onze server",
          description: `Probeer het later opnieuw.`,
          status: "error",
          duration: 600000,
          isClosable: true,
        })
        control.onOpen()
      })
  }

  return (
    <Modal isOpen={control.isOpen} onClose={control.onClose}>
      <ModalOverlay />

      <ModalContent style={{ marginTop: "10vh" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>E-mail kwitantie naar opdrachtgever</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired style={{ marginTop: "0.5rem" }}>
              <FormLabel htmlFor="fname">
                Verstuur kwintantie naar e-mailadres:
              </FormLabel>
              <Input
                id="fname"
                placeholder="administratie@jansenenjansen.nl"
                type="email"
                name="emailTo"
                ref={register()}
                defaultValue={state?.email?.emailTo ?? ""}
              />
            </FormControl>
            <FormControl isRequired style={{ marginTop: "1.5rem" }}>
              <FormLabel htmlFor="copy">Stuur een kopie naar:</FormLabel>
              <Input
                id="copy"
                placeholder="jandevries@gmail.com"
                type="email"
                name="emailFrom"
                ref={register()}
                defaultValue={state?.email?.emailFrom ?? ""}
              />
              <FormErrorMessage>hello</FormErrorMessage>
            </FormControl>

            <FormControl isRequired style={{ marginTop: "1.5rem" }}>
              <FormLabel htmlFor="message">Bericht:</FormLabel>

              <Textarea
                placeholder="Geachte heer / mevrouw "
                size="sm"
                ref={register()}
                name="message"
                id="message"
                defaultValue={state?.email?.message ?? ""}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={control.onClose} mr={3}>
              Annuleer
            </Button>
            <Button variantColor="blue" type="submit">
              Verstuur e-mail
            </Button>
            <hr />
          </ModalFooter>
          <i style={{ padding: "1.5rem", display: "block" }}>
            E-mailadressen worden uitsluitend gebruikt om de kwitantie te
            versturen.
          </i>
        </form>
      </ModalContent>
    </Modal>
  )
}
export default mailModal

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.x
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })

  if (!response.ok) {
    throw "no 200 exception"
  }

  return response.json() // parses JSON response into native JavaScript objects
}
