import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const privacyPage = () => {
  return (
    <Layout>
      <SEO title="privacy en disclaimer" />

      <div className="page noPrint content">
        {/* contentEditable="false" */}
        <h1>Privacy statement en disclaimer</h1>
        <ol>
          <li>
            Alle kwitantie gegevens blijven in de beveiligde browser. Muv van
            versturen per e-mail en downloaden naar pdf. Gegevens worden na het versturen vernietigd.
          </li>
          <li>Alle gegevens worden na gebruik (versturing) vernietigd.</li>
          <li>
            Aan de verstrekte informatie kunnen geen rechten worden ontleend.
          </li>
        </ol>
      </div>
    </Layout>
  )
}

export default privacyPage
