import { useAuth0 } from "@auth0/auth0-react"
import {
  Button,
  FormLabel,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import React, { useCallback, useContext, useState } from "react"
import ProgressBar from "react-topbar-progress-indicator"
import AppContext, { actions, State } from "../context/kwitantieContext"
import featureFlag from "../utils/featureFlags"
import DownloadKwitantieButton from "./downloadKwitantieButton"
import EmailModal from "./modal/mailModal"
import NewModal from "./modal/newModal"
import SaveKwitantieButton from "./saveKwitantieButton"

const KwitantieActionHeader = ({ isHomepage }: { isHomepage: boolean }) => {
  const newModalControl = useDisclosure()
  const emailModalControl = useDisclosure()
  const context = useContext<any>(AppContext)
  const toast = useToast()
  const { isAuthenticated, user } = useAuth0()
  const [isLoading, setIsLoading] = useState(false)
  const { state, dispatch }: { state: State; dispatch: any } = context
  const switchOnChange = useCallback(() => {
    dispatch({
      type: actions.flip_is_paid,
    })
  }, [])

  const print = useCallback(() => {
    window.print()
  }, [])

  return (
    <>
      {isLoading && <ProgressBar />}
      <div
        id="top"
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "#f5f5f5",
          display: "flex",
          justifyContent: "center",
          zIndex: 2,
          flexWrap: "wrap",
        }}
        className="noPrint"
      >
        <DownloadKwitantieButton toast={toast} />
        <Button onClick={print} style={{ margin: "0.5rem" }}>
          Print
        </Button>
        <Button
          onClick={emailModalControl.onOpen}
          style={{ margin: "0.5rem" }}
        >
          Email
        </Button>

        {isHomepage && (
          <Button
            onClick={newModalControl.onOpen}
            style={{ margin: "0.5rem"}}
          >
            Nieuwe kwitantie
          </Button>
        )}
        {featureFlag("develop") && isAuthenticated && (
          <SaveKwitantieButton toast={toast} />
        )}

        {featureFlag("develop") && isAuthenticated && (
          <div style={{ margin: "0.5rem", paddingBottom: "0.4rem"}}>
            <FormLabel htmlFor="email-alerts">Betaald?</FormLabel>
            <Switch
              id="email-alerts"
              isChecked={state?.isPaid ?? false}
              onChange={switchOnChange}
            />
          </div>
        )}
      </div>
      <NewModal control={newModalControl} />
      <EmailModal
        control={emailModalControl}
        toast={toast}
        setIsLoading={setIsLoading}
      />
    </>
  )
}
export default KwitantieActionHeader
