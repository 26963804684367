import { useAuth0 } from "@auth0/auth0-react"
import { Badge, Button, Heading } from "@chakra-ui/react"
import { Link, navigate } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import Kwitantie from "../components/kwitantie"
import KwitantieActionHeader from "../components/kwitantieActionHeader"
import Layout from "../components/layout"
import { AppProvider, State } from "../context/kwitantieContext"
import Loader from "../context/loader"
import { retrieveKwitantieFromLocalStorage } from "../utils/localstorage"
import { decrypt } from "../utils/security"

const IndexPage = () => {
  const [initState] = useState<State | undefined>(() => retrieveKwitantieFromLocalStorage())

  const { isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("overview")
    }
  }, [isAuthenticated])
  const primium = useCallback(() => {
    window.location.href = "https://www.moneybird.nl/aanmelden?referrer=25a1d85909"
  }, [])

  if (typeof window !== "undefined" && (isLoading || isAuthenticated)) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  return (
    <Layout>
      <AppProvider initState={initState}>
        {!isAuthenticated && (
          <div
            style={{
              textAlign: "center",
              margin: "0 auto 1.5rem auto",
              border: "1px solid #ddd",
              padding: "1rem",
              maxWidth: "21cm",
              borderRadius: "7px",
              fontSize: "80%",
            }}
            className="noPrint"
          >
            Creëer gratis een kwitantie. Download naar pdf of verstuur per
            e-mail. Geen wachtwoorden nodig.
          </div>
        )}

        <KwitantieActionHeader isHomepage={true} />
        <Kwitantie />
      </AppProvider>
      <div className="page noPrint defaultText">
        <p>
          <Heading size="lg" as="h3">
            {" "}
            Kwitanties opstellen
          </Heading>
          <p>
            Met de kwitantie.app is het mogelijk om snel kwitanties (
            <Link to="factuur-voor-particulieren">
              facturen voor particulieren
            </Link>
            ) op te maken en te versturen.
          </p>
          <ul>
            <li>
              <span>Stel uw kwitantie op</span>
              <Badge variantColor="green">geen login</Badge>
            </li>
            <li>
              <span>Print en pdf download</span>
              <Badge variantColor="green">Gratis</Badge>
            </li>
            <li>
              Verstuur direct per mail{" "}
              <Badge variantColor="green">Gratis</Badge>
            </li>
          </ul>
          <p>
            Alle verplichte velden staan op bovenstaand formulier. Daarin typt u
            direct de benodigde gegevens. Eventueel verandert u de rest van de
            tekst naar eigen inzicht. Daarna kunt u de kwitantie opslaan als
            pdf, printen of versturen per e-mail.
          </p>
          <br />
 <Heading size="md" as="h3">
    Voor de power users: Moneybird
  </Heading>
  <ul>
    <li>Volledige en eenvoudige boekhoudfunctionaliteiten</li>
    <li>Automatische bankkoppelingen</li>
    <li>Uitgebreide rapportages en financiële overzichten</li>
    <li>Vanuit tijdregistratie gelijk een kwitantie aanmaken</li>
    <li>Eenvoudige voorbereiding voor de Belastingdienst</li>
    <li>Naadloze BTW-aangifte ondersteuning</li>
  </ul>
</p>

        <p>
          <Button variantColor="teal" size="lg" onClick={primium}>
            Probeer gratis
          </Button>
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
